import React from "react";
import Seo from "../components/seo";
import { Container, Typography, Box } from "@mui/material";

const NotFound = () => (
  <Container component="main" maxWidth="lg" className="login-wrapper">
    <Seo title="404: Not Found" />
    <Box textAlign="center" py={5}>
      <Typography variant="h1" gutterBottom>
        404: Not Found
      </Typography>
      <Typography variant="body1">
        You just hit a route that doesn&#39;t exist... the sadness.
      </Typography>
    </Box>
  </Container>
);

export default NotFound;
